import React, { useEffect } from "react";

import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Privilege from "./page/Privilege/Privilege";
import Detail from "./page/Detail/Detail";
import Shipping from "./page/Shipping/Shipping";
import Other from "./page/Other/Other";
import Error from "./page/Error/Error";
import Address from "./page/Address/Address";
import Upload from "./page/Upload/Upload";
import CreateAddress from "./page/Address/CreateAddress";
import useQueryToken from './components/useQueryToken'
import useQueryId from './components/useQueryId'
import Consent from "./page/Consent";
import ListCoupon from "./page/ListCoupon";



import { Link, useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { apiUrl } from './constants';
import { KEYTEST } from './constants';
import axios from "axios";
const SecuredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => (
        <Component {...props} />
      )
    }
  />
);

export default function App() {
  const token = useQueryToken();
  const id = useQueryId();
  const pathname = window.location.pathname;
  const history = useHistory();

  const redirectToError = () => {
    return <Redirect to="/Error" />;
  };

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("id", JSON.stringify(id ? id.replace(/\?%3F/g, '?').replace(/%3D/g, '=').replace(/%26/g, '&') : null));

    //เชื่อม GA
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + KEYTEST;
    script.async = true;
    document.head.appendChild(script);
    // Initialize Google Tag Manager
    var dataLayer = window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', KEYTEST);

  }, [])

  const checktoken = (token) => {
    let apiUrls = `${apiUrl}/client/me`;
    try {
      return axios({
        method: "get",
        url: apiUrls,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        localStorage.setItem("User", JSON.stringify(res.data))
        // console.log(true)
        return true
      }).catch((err) => {
        history.push("/Error")
      });
    } catch (error) {
      history.push("/Error")
    }
  }


  const checkid = (id) => {
    if (id) {
      return true
    } else {
      return false
    }
  }

  return (
    <Switch>
      {pathname === "/privilege" || pathname === "/other" ?
        checktoken(token) ?
          <>
            <Route path="/privilege" component={Privilege} />
            <Route path="/other" component={Other} />
          </>
          : <SecuredRoute path="/Error" component={Error} />
        :
        pathname === "/Detail" || pathname === "/Shipping" || pathname === "/Address" || pathname === "/Upload" || pathname === "/CreateAddress" || pathname === "/EditAddress" ?
          checktoken(token) && checkid(id) ?
            <>
              <SecuredRoute path="/Detail" component={Detail} />
              <SecuredRoute path="/Shipping" component={Shipping} />
              <SecuredRoute path="/Address" component={Address} />
              <SecuredRoute path="/Upload" component={Upload} />
              <SecuredRoute path="/CreateAddress" component={CreateAddress} />
              <SecuredRoute path="/EditAddress" component={CreateAddress} />
            </>
            :
            <SecuredRoute path="/Error" component={Error} />
          :
          <SecuredRoute path="/Error" component={Error} />
      }
      <SecuredRoute path="/Consent" component={Consent} />

      <Route path="/ListCoupon" component={ListCoupon} />

      <Route exact={true} path="/" component={redirectToError} />
      <Route exact={true} path="*" component={redirectToError} />




    </Switch>
  );
}


