import React, { useState, useEffect } from 'react'
import "./mycss.scss";
import liff from '@line/liff'
import axios from "axios";
import { apiUrl } from '../../constants';
import HeaderBackPoint from "../../components/HeaderBackPoint"
import { Tab, Tabs } from "react-bootstrap";
import "./Listcoupon.scss";
import { useHistory } from "react-router-dom";
import { fade, List } from '@material-ui/core';
import Swal from "sweetalert2";
import OpenRewardModal from "../../components/OpenRewardModal"


const Index = () => {

  const [showReward, setShowReward] = useState(false);
  const [dataReward, setDataReward] = useState();

  const [coupon, setCoupon] = useState();
  const history = useHistory();
  let token = localStorage.getItem("token")
  const tokens = history.location.search.split("?token=")
  const cover_token = token ? JSON.parse(token) : tokens[1]
  // const token_tmp = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExMzFjZGMxLTI2MmQtNGFmOC1iNjYxLTJmMTk4ZTU2Nzk4OSIsImlhdCI6MTczOTQ0MjM0Nn0.DYG62flf3VxXPlrf44bWH93SyI4oe8dN2L-T197uKZE';
  const ListCoupon = async () => {
    await axios.get(`${apiUrl}/privilege/history?page=1&limit=100&is_delivery=0`, { headers: { Authorization: `Bearer ${cover_token}` } })
      .then((res) => {
        console.log('res : ', res.data.data)
        // setIsLoading(false)
        // liff.closeWindow();
        setCoupon(res.data.data)
      })
      .catch(() => {
        // setIsLoading(false)
      });
  }

  
  // // let id = localStorage.getItem("id")
  // const cover_id = id ? JSON.parse(id) : ""
  let url = new URL(window.location.href)
  const tab = url.searchParams.get("tab"); // coupon,used
  const [key, setKey] = useState('coupon');

  useEffect(() => {
    // if (tab === "used") {
    //   setKey("used")
    // } else {
    //   setKey("coupon")
    // }
  }, [tab])

  useEffect(() => {
    ListCoupon()
  }, [])

  const handleClose = () => {
    setShowReward(false)
    setDataReward()
    ListCoupon()
  }


  const showQRcode = async (data) => {
    const nowDate = new Date();
    if(data.expire_used_at) {
      if(new Date(data.expire_used_at) > nowDate) {
        setDataReward(data)
        setShowReward(true)
      }else {
        Swal.fire({
          title: 'ผิดพลาด',
          text: 'คูปองหมดอายุแล้ว',
          icon: 'error',
          confirmButtonColor: '#00833b',
          confirmButtonText: 'ตกลง',
        });
      }

      ListCoupon()
    }else {
      const result = await Swal.fire({
        title: 'ยืนยันการใช้งานคูปอง',
        text: "เมื่อยืนยันแล้วคูปองจะมีอายุ 15 นาที และไม่สามารถกดยกเลิกหรือเปลี่ยนแปลงได้",
        icon: 'warning',
        confirmButtonColor: '#00833b',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'swal-btn-confirm',
          cancelButton: 'swal-btn-cancel',
        },
      });
      console.log("Swal result:", result.value);
      if (result.value) {
        console.log('confirm');
  
  
        const apiUrls = `${apiUrl}/privilege/usedReward`;
        axios({
          method: "post",
          url: apiUrls,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${cover_token}`,
          },
          data: {
            privilege_redeem_id: data.privilege_redeem_id,
            // qty: 1
          }
        }).then(async (res) => {
          console.log('res : ', res.data.data)
          setDataReward(res.data.data)
          setShowReward(true)
  
        }).catch(function (error) {
          if (error.response) {
            Swal.fire({
              title: "ผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              confirmButtonText: "ตกลง",
              confirmButtonColor: '#00833b',
            });
          }
        });
  
      }
    }
    

  }

  // const renderCoupon = (data) => {
  //   return (
  //     <div className="" key={data.id} style={{
  //       display: 'flex',
  //       // padding: '10px',
  //       borderBottom: '1px solid #ccc',
  //       border: 'rgba(0, 0, 0, 0.05) solid 1px',
  //       borderRadius: '12px',
  //       marginBottom: '5px',
  //       // add shadow
  //       boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
  //     }
  //     }>


  //       <div className="" style={{ width: '40%', }}>
  //         <img style={{ borderRadius: '12px 0px 0px 12px', }} src={data.deal_image} alt={data.deal_name} />
  //       </div>

  //       <div className="" style={{ width: '60%', paddingLeft: '5px' }}>

  //         <div className="" style={{ lineHeight: '1.5rem' }}>
  //           {data.deal_name}
  //         </div>

  //         {/* {data.point && ( */}
  //         <div className="" >
  //           {data.point} <span style={{
  //             fontWeight: 'bold',
  //             fontSize: 'larger',
  //             color: '#00833b'
  //           }}>50</span> คะแนน
  //         </div>
  //         {/* )} */}



  //         <div className="">{data.content}</div>

  //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

  //           <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '90%', }}>
  //             <span>
  //               xxxxxxxxxxxxxxxxxxx
  //             </span>
  //             <span>
  //               หมดอายุ {data.expire_at} น.
  //             </span>
  //           </div>

  //           <div className="" style={{ width: '90%', marginRight: '5px' }}>
  //             {data.is_used === false ? (
  //               <button className="" style={{
  //                 backgroundColor: '#00833b',
  //                 color: '#fff',
  //                 padding: '0px 14px',
  //                 border: 'none',
  //                 borderRadius: '24px',
  //                 cursor: 'pointer',
  //                 fontSize: 'smaller',
  //                 whiteSpace: 'nowrap',
  //               }} onClick={() => showQRcode(data.deal_id)}>ดูคูปอง</button>
  //             ) : (
  //               <span className="" >ใช้แล้ว</span>
  //             )}
  //           </div>
  //         </div>
  //       </div>

  //     </div >
  //   );
  // };

  const renderCoupon = (data, index) => {
    return (
      <div className="coupon-card" key={data.id}>
        {/* รูปภาพคูปอง */}
        <div className="coupon-image">
          <img src={data.deal_image} alt={data.deal_name} />
        </div>

        {/* ข้อมูลคูปอง */}
        <div className="coupon-content">
          <div className="coupon-header">
            <h4 className="coupon-title">{data.deal_name}</h4>
            {/* ตัวอย่าง ถ้าคุณมีเงื่อนไขว่าคูปองนี้เป็นของใหม่ ให้โชว์ label New */}
            {data.isNew && <span className="coupon-label-new">New</span>}
          </div>

          {/* คะแนนหรือส่วนลด (เช่น 50 คะแนน / 50%) */}
          <div className="coupon-value">
            {data.deal_point >= 0 && (
              <>
                <span className="value">{data.deal_point}</span>
                <span className="unit"> คะแนน</span>
              </>
            )}
          </div>

          {/* รายละเอียดสั้น ๆ ของคูปอง (content) */}
          {/* <div className="coupon-description">
            {data.content}
          </div> */}

          {/* วันหมดอายุ หรือข้อความอื่น ๆ */}

          <div className="coupon-expire">
            {data.created_at && (
              <> แลกเมื่อ {formatThaiDate(data.created_at)}</>
            )}
          </div>
          <div className="coupon-expire">
            {data.is_expired === true ? (
              <>
                <span style={{ color: 'red' }}>หมดอายุแล้ว</span>
              </>
            ) : (
              <>
                {data.expire_at && (<> <span>หมดอายุ {formatThaiDate(data.expire_at)}</span></>)}
              </>
            )}
          </div>
        </div>

        {/* ปุ่มหรือสถานะคูปอง */}
        <div className="coupon-action">
          {data.is_used === false && (
            <>
              {data.is_expired === true ? (
                <button
                  className="btn-coupon"
                  disabled={true}
                  style={{
                    backgroundColor: 'lightgray',
                    color: 'white',
                  }}
                >
                  หมดอายุแล้ว
                </button>
              ) : (
                <button
                  className="btn-coupon"
                  onClick={() => showQRcode(data)}
                  // onClick={() => showQRcode(data.privilege_redeem_id)}
                >
                  ใช้คูปอง
                </button>
              )}
            </>
          )}

          {data.is_used === true && data.expire_used_at !== null && new Date(data.expire_used_at) > new Date() && <button
                  className="btn-coupon"
                  onClick={() => showQRcode(data)}
                  // onClick={() => showQRcode(data.privilege_redeem_id)}
                >
                  ใช้คูปอง
                </button>}
        </div>
      </div >
    );
  };

  function formatThaiDate(dateString) {
    // dateString = "2024-02-01T09:40:10.000Z"
    // แยกส่วนวันที่ (YYYY-MM-DD) กับเวลา (HH:MM:SS.fffZ)
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');   // year=2024, month=02, day=01
    const [hour, minute] = timePart.split(':');       // hour=09, minute=40 (ส่วน seconds .000Z จะถูกตัดทิ้ง)

    // รายชื่อเดือนภาษาไทย
    const thaiMonths = [
      "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
      "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
      "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
    ];

    // parseInt(day) เพื่อให้เป็นเลขไม่ติด 0 ข้างหน้า
    // เช่น 01 => 1
    const d = parseInt(day, 10);
    const m = parseInt(month, 10);

    return `วันที่ ${d} ${thaiMonths[m - 1]} ${year} ${hour}:${minute}`;
  }

  // ตัวอย่างการใช้งาน
  // const time = "2024-02-01T09:40:10.000Z";
  // console.log(formatThaiDate(time));
  // ได้ผลลัพธ์: "วันที่ 1 กุมภาพันธ์ 2024 09:40"




  return (
    <div className="listcoupon-page">
      <div className="title-header p-3">
        <HeaderBackPoint topic={'คูปองของฉัน'} path={`/privilege?token=${cover_token}`} />
      </div>

      {/* <Tabs id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      > */}
      {/* <Tab eventKey="coupon" title="คูปองพร้อมใช้งาน"> */}
      <div className="noti-tab container" style={{ paddingTop: '10px' }}>
        {coupon && coupon.length > 0 ? (
          coupon.map((data) => {
            // if (data.is_used === false && key === 'coupon') {
            return renderCoupon(data);
            // }
            // return null;
          })
        ) : (
          <div className="d-flex py-3 noti-list">
            <div className="col-12 text-center">
              <span className="mb-1 font-weight-bold">ไม่มีคูปอง</span>
            </div>
          </div>
        )}
      </div>
      {/* </Tab> */}
      {/* <Tab eventKey="used" title="คูปองใช้งานแล้ว">
          <div className="noti-tab container">
            {coupon && coupon.length > 0 ? (
              coupon.map((data) => {
                if (data.is_used === true && key === 'used') {
                  return renderCoupon(data);
                }
                return null;
              })
            ) : (
              <div className="d-flex py-3 noti-list">
                <div className="col-12 text-center">
                  <span className="mb-1 font-weight-bold">ไม่มีคูปอง</span>
                </div>
              </div>
            )}
          </div>
        </Tab> */}
      {/* </Tabs> */}


      {showReward && (

        <OpenRewardModal
          open={showReward}
          privilege={dataReward}
          handleClose={handleClose}
        />

      )}

    </div>
  )
}

export default Index
